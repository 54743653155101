import { render } from 'react-dom';
import './config/globals';
import 'normalize.css';
import './index.scss';
import AppFrame from './components/AppFrame/AppFrame';
import App from './App';
import Blog from './pages/Blog/Blog';
import BlogPost from './pages/Blog/BlogPost';
import Contact from './pages/Contact/Contact';
import Projects from './pages/Projects/Projects';
import Cv from './pages/Cv/Cv';
import NotFound from './components/NotFound/NotFound';
import { ScrollToTop, theme } from './utils';
import { Router } from '@reach/router';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

const AppRouter = (
  <MuiThemeProvider theme={theme}>
    <AppFrame>
      <Router>
        <ScrollToTop path='/'>
          <App path='/' />
          <Blog path='blog' />
          <BlogPost path='blog/:blogTitle' />
          <Contact path='contact' />
          <Projects path='projects' />
          <Cv path='cv' />
          <NotFound default />
        </ScrollToTop>
      </Router>
    </AppFrame>
  </MuiThemeProvider>
);

render(AppRouter, document.getElementById('root'));
