export const PROJECTS =  [
  {
    name: 'Hot Crete',
    description: 'A website to aid customer engagement for construction company.',
    technologies: ['Javascript', 'React', 'SCSS', 'Hooks', 'AWS'],
    dates: 'June 2019',
    canVisit: false,
  },
  {
    name: 'Personal Website',
    description: 'A website for my visitors\' liking.',
    technologies: ['Javascript', 'React', 'SCSS', 'Hooks', 'AWS', 'Serverless'],
    url: 'https://alfredoyebra.com',
    dates: 'June 2019',
    canVisit: true,
  },
  {
    name: 'Scattergories',
    description: 'A web app implementation of the classic game of Scattergories.',
    technologies: ['Javascript', 'React', 'SCSS', 'Hooks', 'Git', 'AWS'],
    url: 'https://play-scattergories.com',
    dates: 'June 2019',
    canVisit: true,
  },
];
