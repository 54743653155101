import './Cv.scss';
import atx from '../../assets/images/0.jpeg';
import Tags from '../../components/Tags/Tags';
import { SKILLS, RELEVANT_COURSES, EXPERIENCE } from './CvData';

export default function Cv() {
  const createHeader = header => (
    <div className='cv-section'>
      <div className='cv-section-left'></div>
      <div className='cv-section-right cv-section-header'>{header}</div>
    </div>
  );

  const header = (
    <div className='cv-section'>
      <div className='cv-section-left'>
        <img className='cv-header-img' src={atx} alt='Freddie and Clarissa' />
      </div>
      <div className='cv-section-right header'>
        <h1 className='name'>Alfredo Yebra Jr.</h1>
        <h3 className='my-title'>Software Developer</h3>
        <p className='cv-header-description'>Motivated software developer with a passion for creating clean, robust, and re-usable software solutions for meaningful projects.</p>
        <a className='contact-link' href='tel:5126800099'>512.680.0099</a>
        <span style={{marginLeft: 2, marginRight: 2}}>/</span>
        <a className='contact-link' href='mailto:a.yebra022@gmail.com'> a.yebra022@gmail.com</a>
        <span style={{marginLeft: 2, marginRight: 2}}>/</span>
        <a className='contact-link' href='https://www.linkedin.com/in/alfredo-yebra-jr-688257116/'> LinkedIn</a>
      </div>
    </div>
  );

  const experience = (
    <>
      {createHeader('Experience')}
      {EXPERIENCE.map((position, i) => (
        <div className='cv-section experience' key={i}>
          <div className='cv-section-left'>
            <div className='company'>
              <p>{position.company}</p>
            </div>
            <div className='dates'>
              <p>{position.dates}</p>
            </div>
          </div>
          <div className='cv-section-right'>
            <h4 className='title'>{position.title}</h4>
            <p className='description'>{position.description}</p>
            <ul className='experience-list'>
              {position.highlights.map((highlight, i) => <li key={i}>{highlight}</li>)}
            </ul>
          </div>
        </div>
      ))}
    </>
  );

  const education = (
    <>
      {createHeader('Education')}
      <div className='cv-section'>
        <div className='cv-section-left'>
          <div className='company'>
            <p>Texas State University</p>
          </div>
          <div className='dates'>
            <p>2014 - 2018</p>
          </div>
        </div>
        <div className='cv-section-right'>
          <div className='title'>BSc in Computer Science <span style={{fontSize: 12}}>focus in</span> Computer Engineering</div>
          <div className='description'>Minor in Mathematics</div>
          <Tags tags={RELEVANT_COURSES} />
        </div>
      </div>
    </>
  );

  const skills = (
    <>
      {createHeader('Skills')}
      <div className='cv-section'>
        <div className='cv-section-left'></div>
        <div className='cv-section-right'>
          <Tags tags={SKILLS} />
        </div>
      </div>
    </>
  );

  return (
    <div className='cv-wrap'>
      <div className='cv'>
        {header}
        {experience}
        {education}
        {skills}
      </div>
    </div>
  );
}
