import './Projects.scss';
import { Button, Card, Chip, CardContent, CardActions } from '@material-ui/core';
import { PROJECTS } from './ProjectsData';

export default function Projects() {
  const projectsList = PROJECTS.map((project, i) => (
    <Card className='project' key={i}>
      <CardContent style={{paddingBottom: 0}}>
        <div className='project-header'>
          <h1 className='project-name'>{project.name}</h1>
          <h4 className='project-dates'>{project.dates}</h4>
        </div>
        <p className='project-description'>{project.description}</p>
        <div className='project-chips'>
          {project.technologies.map((technology, j) => (
            <div className='project-chip' key={j}>
              <Chip
                key={i}
                size='small'
                variant='outlined'
                label={technology}
              />
            </div>
          ))}
        </div>
      </CardContent>
      <CardActions>
        {project.canVisit && <Button component={'a'} target='_blank' href={project.url}>Visit</Button>}
      </CardActions>
    </Card>
  ));

  return (
    <div className='projects'>
      {projectsList}
    </div>
  );
}
