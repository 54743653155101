import './Header.scss';
import logo from '../../assets/images/alfredoyebrajr-logo.png';
import { AppBar, Drawer, IconButton, List, ListItem, ListItemText, Tab, Tabs, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'transparent'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main
  },
}));

export default function Header() {
  const classes = useStyles();
  const [value, setValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e, newValue) => setValue(newValue);
  const handleReset = e => setValue(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const links = ['Cv', 'Projects', 'Blog', 'Contact'];
  const drawer = (
    <Drawer
      variant='temporary'
      anchor='top'
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <List>
        {links.map((text, i) => (
          <ListItem component={Link} to={text.toLowerCase()} onClick={handleClose} button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <IconButton onClick={handleClose}>
        <ClearIcon />
      </IconButton>
    </Drawer>
  );

  return (
    <>
      <AppBar position='sticky' className={classes.appBar}>
        <Toolbar color='primary' className={classes.toolbar}>
          <Link className='logo-container' to='/' onClick={handleReset}>
            <img className='logo' src={logo} alt='ayj' />
          </Link>
          <IconButton className='menu-icon-btn' onClick={handleOpen}>
            <MenuIcon className='menu-icon' />
          </IconButton>
          <div className='tabs'>
            <Tabs
              TabIndicatorProps={{style: { backgroundColor: '#52de97'}}}
              className='tabs'
              value={value}
              onChange={handleChange}
            >
              {links.map((link, i) => <Tab label={link} component={Link} to={link.toLocaleLowerCase()} key={i} />)}
            </Tabs>
          </div>

        </Toolbar>
      </AppBar>
      {drawer}
    </>
  );
}
