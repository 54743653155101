
export const EXPERIENCE = [
  {
    company: 'Aunt Bertha',
    dates: '10/14 - Present',
    title: 'Software Engineer',
    url: 'auntbertha.com',
    description: '',
    highlights: []
  },
  {
    company: 'Hot Crete',
    dates: '7/3 - 10/1',
    title: 'Contract Web Developer',
    url: 'hot-crete.com',
    description: 'Build website for construction company to aid customer engagement.',
    highlights: [
      'Collect requirements and plan implementation.',
      'Build front-end website.',
      'Setup Google Analytics.',
    ]
  },
  {
    company: 'Quality People',
    dates: '2/2019 - 6/2019',
    title: 'Jr. Full-stack Software Engineer',
    description: 'Built and mantained legacy CRM platform to help schools manage leads.',
    highlights: [
      'Implemented full-stack application features on MERN stack.',
      'Setup email marketing using AWS SES.',
      'Created static landing pages and forms for email campaigns.',
      'Created custom back-ends using AWS and Serverless with MongoDB and Node.',
      'Extensively implemented Python scripts to manipulate excel and csv files.',
    ]
  },
  {
    company: 'Austin Community College',
    dates: '10/2018 - 2/2019',
    title: 'Computer Science Tutor III',
    description: 'Tutored and helped CS students with coding assignments and various CS topics.',
    highlights: [
      'Helped students with programming assignments in Python, C, and C++.',
      'Worked in small groups to aid collaborative programming.',
    ]
  },
  {
    company: 'Loom',
    dates: '5/2016 - 5/2017',
    title: 'Web Developer',
    description: 'Helped build a web app for freelancers to connect with entrepreneurs.',
    highlights: [
      'Worked in agile environment.',
      'Implemented sign-up and sign-in with social media.',
      'Contributed to collaborative codebase.',
      'Discussed implementation of wireframes with designers.',
    ]
  },
  {
    company: 'Innoverted',
    dates: '8/2015 - 1/2016',
    title: 'Jr. Full-stack Web Developer',
    description: 'Helped build web app for lawncare companies.',
    highlights: [
      'Quickly learned new tech stack.',
      'Pair programmed to solve difficult tasks.',
      'Wrote unit tests.',
      'Implemented full-stack features.'
    ]
  }
];

export const SKILLS = [
  'python',
  'javascript',
  'html / html5',
  'css / css3',
  'react',
  'django',
  'express',
  'mongodb',
  'node',
  'c / c++',
  'arduino',
  'agile',
  'git',
  'jira',
];

export const RELEVANT_COURSES = [
  'data structures',
  'software engineering I, II',
  'oop',
  'comp. architecture',
  'os',
  'embedded systems',
  'comp. networks',
  'prob & stats',
  'discrete math I, II',
  'linear algebra',
  'calc I, II, III'
];