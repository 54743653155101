import './Tags.scss';

export default function Tags(props) {
  const tags = props.tags.map((tag, i) => (
    <div className='tag' key={i}>
      {tag}
    </div>
  ));

  return (
    <div className='tags'>
      {tags}
    </div>
  );
}
