import './Contact.scss';
import { Button, FormControl, TextField } from '@material-ui/core';
import useForm from './useForm';
import { validateContactForm } from './validator';

export default function Contact(props) {
  const {
    values: { name, email, phone, message },
    errors,
    handleChange,
    handleSubmit
  } = useForm(sendForm, validateContactForm);

  function sendForm() {
    fetch('https://hqs3fhb74g.execute-api.us-east-1.amazonaws.com/dev/', {
      method: 'post',
      body: JSON.stringify({ name, email, phone, content: message})
    })
      .then(res => {
        if (res.status === 200) {
          alert('success!');
        }
      });
  }

  const err = (errMessage) => <p style={{color: 'red'}}>{errMessage}</p>;

  return (
    <div className='contact'>
      <h1 style={{textAlign: 'center'}}>Send a message</h1>
      <form className='contact-form' onSubmit={handleSubmit} noValidate>
        <FormControl className='contact-form-field' fullWidth>
          <TextField
            label='Name'
            name='name'
            value={name || ''}
            onChange={handleChange}
            required
          />
          {errors.name && err(errors.name)}
        </FormControl>
        <FormControl className='contact-form-field' fullWidth>
          <TextField
            label='Email'
            name='email'
            value={email || ''}
            onChange={handleChange}
            required
          />
          {errors.email && err(errors.email)}
        </FormControl>
        <FormControl className='contact-form-field' fullWidth>
          <TextField
            label='Phone'
            name='phone'
            value={phone || ''}
            onChange={handleChange}
            required
          />
          {errors.phone && err(errors.phone)}
        </FormControl>
        <FormControl className='contact-form-field' fullWidth>
          <TextField
            label='Message'
            name='message'
            value={message || ''}
            onChange={handleChange}
            multiline
            rows='4'
            rowsMax='4'
            required
          />
          {errors.message && err(errors.message)}
        </FormControl>
        <Button variant='outlined' type='submit'>
          Submit
        </Button>
      </form>
    </div>
  );
}
