import {
  Box,
  Button,
  Grid,
  // Link as ButtonLink,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import BLOG_POSTS from './BlogPosts';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.secondary.main,
    paddingRight: 14,
  },
  date: {
    fontSize: 18
  },
  summary: {
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14
    // color: '#a3a3a3'
  },
}));

export default function Blog() {
  const classes = useStyles();

  return (
    <Box>
      {BLOG_POSTS.map((post, i) => (
        <Box component={Paper} mx={4} my={3} p={3} key={i}>
          <Grid container alignItems='flex-end'>
            <Typography variant='h4' className={classes.title}>
              {/* <Link
                component={Link} to={`${post.title.replace(' ', '-')}`}
                state={{post}}
              > */}
              {post.title}
              {/* </Link> */}
            </Typography>
            <Box className={classes.date} component={Typography} color='#a3a3a3'>{post.date}</Box>
          </Grid>
          <Typography className={classes.summary}>{post.summary}</Typography>
          <Box mt={1}>
            <Button
              color='primary'
              size='small'
              variant='outlined'
              className={classes.readBtn}
              component={Link} to={`${post.title.replace(' ', '-')}`}
              state={{post}}
            >
              read
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
