import { createMuiTheme } from '@material-ui/core/styles';

export let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#394a6d',
    },
    secondary: {
      main: '#3c9d9b'
    }
  },
  typography: {
    useNextVariants: true,
  },
});
//3rd #52de97
//4th #c0ffb3;

export function ScrollToTop({ children, location }) {
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return children;
}
