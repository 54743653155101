import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div style={{ marginTop: 60, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <h2 style={{ marginBottom: 10 }}>Whoops, something went wrong!</h2>
      <Button variant='outlined' component={Link} to='/'>
        Go Home
      </Button>
    </div>
  );
}
