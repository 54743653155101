import me from './assets/images/freddieyebra.jpg';
import github from './assets/images/icon-github.png';
import linkedin from './assets/images/icon-linkedin.png';

function App() {
  return (
    <>
      <div className='home'>
        <div className='home-header'>
          <div className='home-header-section'>
            <img className='home-header-img' src={me} alt='Alfredo Yebra Jr.' />
          </div>
          <div className='home-header-section'>
            <h1 className='home-header-title'>Freddie Yebra</h1>
            <h6 className='home-header-subtitle'>Software Engineer / Maker</h6>
            <div className='social-media'>
              <a href='https://github.com/yebra06'>
                <img className='social-media-icon' src={github} alt='github' />
              </a>
              <a href='https://www.linkedin.com/in/alfredo-yebra-jr-688257116/'>
                <img className='social-media-icon' src={linkedin} alt='linkedin' />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='projects'>
        <h1 style={{textAlign: 'center', paddingTop: 20}}>Recent Projects</h1>
        <ProjectCards/>
      </div> */}
    </>
  );
}

export default App;
