import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CodeBlock from '../../components/CodeBlock/CodeBlock';

const useStyles = makeStyles(theme => ({
  blogPost: {
    margin: theme.spacing(3),
  },
  sectionHeader: {
    fontWeight: 500
  },
  sectionParagraph: {
    fontSize: 21
  },
  date: {
    fontSize: 18,
    color: theme.palette.secondary.light
  }
}));

export default function BlogPost({ location }) {
  const classes = useStyles();
  const { post: { date, sections, title } } = location.state;

  useEffect(() => {
    document.title = title;
    return () => document.title = 'Alfredo Yebra Jr.';
  });

  const formatSection = (section) => {
    const { content, sectionType, language, sectionSummary } = section;

    switch (sectionType) {
    case 'h':
      return (
        <>
        <Typography component='div'>
          <Box mt={3} fontSize='h4.fontSize' fontWeight='fontWeightBold'>{content}</Box>
        </Typography>
        {sectionSummary && (
          <Typography className={classes.sectionParagraph} component='div'>
            <Box mt={1}>{sectionSummary}</Box>
          </Typography>
        )}
        </>
      )
    case 'p':
      return (
        <Typography className={classes.sectionParagraph} component='div'>
          <Box my={2.5} component='p'>{content}</Box>
        </Typography>
      )
    case 'c':
      return (
        <Box my={1}>
          <CodeBlock language={language} value={content} />
        </Box>
      )
    default:
      return ''
    }
  }

  return (
    <Container>
      <Box my={2}>
        <Typography variant='h2'>{title}</Typography>
        <Typography className={classes.date}>{date}</Typography>
      </Box>
      <Box mt={1}>
        {sections.map((section, i) => (
          <div key={i}>{formatSection(section)}</div>
        ))}
      </Box>
    </Container>
  );
}