
export function validateContactForm(values) {
  let errors = {};
  const emailRe = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);
  const phoneRe = RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/);

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!emailRe.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  if (!values.phone) {
    errors.phone = 'Phone number is required';
  } else if (!phoneRe.test(values.phone)) {
    errors.phone = 'Phone number is invalid format';
  }

  if (!values.message) {
    errors.message = 'Message is required';
  }

  return errors;
};