import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

global.React = React;
global.PropTypes = PropTypes;
global.useState = useState;
global.useEffect = useEffect;
global.Box = Box;
global.Container = Container;
global.Grid = Grid;
global.makeStyles = makeStyles;
global.Typography = Typography;
