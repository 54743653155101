import './AppFrame.scss';
import Header from '../Header/Header';
import CssBaseline from '@material-ui/core/CssBaseline';

const AppFrame = ({ children }) => (
  <div className='app-frame'>
    <CssBaseline/>
    <Header/>
    <main>{children}</main>
    <footer>Alfredo Yebra Jr. -- 2019</footer>
  </div>
);

AppFrame.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppFrame;
