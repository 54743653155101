
const BLOG_POSTS = [
  {
    title: 'First Post',
    date: 'October 9, 2019',
    categories: ['random'],
    summary: 'Today is the day! The great birthday of my very own blog!',
    sections: [
      {
        sectionType: 'h',
        content: 'What to expect.',
        sectionSummary: `
          My blog will consist of my ideas, thoughts, and examples / tutorials of various
          things I learn, or do everyday.`,
      },
      {
        sectionType: 'p',
        content: `Often, I have instances where I feel that my thoughts, or past
          experiences may spark one's interest, or aid someone in need.`
      },
      {
        sectionType: 'c',
        language: 'python',
        content: `
          def main():
            print('first post')

          if __name__ == 'main':
            main()
        `
      }
    ]
  }
];

export default BLOG_POSTS;
